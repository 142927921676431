@font-face {
  font-family: Fractul-Medium;
  src: url(./fonts/fractul/Fractul-Medium.otf);
}
@font-face {
  font-family: Fractul-Regular;
  src: url(./fonts/fractul/Fractul-Regular.otf);
}
@font-face {
  font-family: Fractul-Semibold;
  src: url(./fonts/fractul/Fractul-SemiBold.otf);
}
@font-face {
  font-family: Argon-Regular;
  src: url(./fonts/argon/Argon_Regular1.otf);
}
@font-face {
  font-family: Argon-Oblique;
  src: url(./fonts/argon/Argon_Oblique1.otf);
}

html, body {
  font-size: 15px;
  overflow-x: hidden;
  background: #fbfbfa !important;
  font-family: 'Fractul-Regular', sans-serif !important;
  font-size: 15px !important;
  /*background: linear-gradient(180deg, rgba(251,251,250,1) 0%, rgba(240,240,240,1) 100%) !important;*/
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}
.nav-link {
  font-family: 'Fractul-Medium', sans-serif;
  color: #000 !important;
  font-size: 15px !important;
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.btn-outline-primary {
  font-family: 'Fractul-Semibold', sans-serif !important;
  border-radius: 0px !important;
  padding: 10px 20px !important;
  letter-spacing: 3px;
}
.form-control {
  font-family: 'Fractul-Regular', sans-serif !important;
  font-size: 15px !important;
  color: #898989 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #898989 !important;
  border-radius: 0 !important;
  margin-bottom: 15px !important;
  background-color: #fbfbfa !important;
}
.col-md-4-ext {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}

#service_0, #service_1, #service_3 {
  position: absolute;
}
#proyect_1, #proyect_2, #proyect_3, #proyect_4 {
  position: relative;
}

.hide {
  display: none;
}
.send-background {
  z-index: -1;
}
.send-front {
  z-index: 100;
}
.send-front-90 {
  z-index: 90;
}
.hr-short {
  width: 30px;
  color: #000;
}
.hr-custom {
  width: 100px;
  color: #000;
}
.full-container {
  /*background-color: #fff;*/
  max-width: 100% !important;
  padding: 0 !important;
}
.logo {
  width: 40%;
}
.nav-bar-custom {
  background-color: #fff;
  padding: 10px 0 !important;
}
.nav-bar-custom-start {
  background-color: transparent !important;
  padding: 10px 0 !important;
}
.brand-image-start {
  height: 30px;
  cursor: pointer;
}
.home-section {
  padding: 90px 0 50px 0!important;
}
.header-title {
  font-family: 'Argon-Regular', sans-serif;
  color: #898989;
  font-size: 20px;
  text-align: center;
  letter-spacing: 8px;
}
.header-img {
  width: 100%;
}
.section {
  padding: 50px 0 !important;
}
.section-title-icon {
  width: 20px;
  margin-right: 15px;
}
.section-title {
  font-family: 'Fractul-Medium', sans-serif;
  color: #000 !important;
  font-size: 20px !important;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.section-desc {
  font-family: 'Fractul-Regular', sans-serif;
  color: #000 !important;
  font-size: 17px !important;
  padding: 30px 0 30px 100px;
  line-height: 30px;
}
.section-inner-scroll {
  position: relative;
  height: 650px;
  /*overflow-y: scroll;*/
}
.form-container {
  position: relative;
}
.slide-logo {
  position: relative;
  background: transparent;
}
.slide-form {
  position: relative;
  background-color: #fbfbfa;
  border: 1px solid #898989;
  padding: 30px;
  text-align: left;
  width: 350px;
}
.label-custom {
  font-family: 'Fractul-Medium', sans-serif;
  font-size: 12px;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-align: left;
  color: #595A5C
}
.carousel-item-custom {
  height: 427px;
}
.slideRightToLeft {
  position: absolute;
  width: 100%;
  top: -50%;
  background-color: transparent;
  margin-left: 110%;
  transition: all 0.5s;
}
.slideRightToLeft.show {
  display: block;
  margin-left: 0;
}
.trazo-carousel {
  width: 70%;
}
.trazo-container {
  position: relative;
  padding: 0 !important;
}
.trazo-style {
  width: 101%;
}
.do-title {
  font-family: 'Argon-Oblique', sans-serif;
  font-size: 50px;
  text-align: right;
  text-transform: uppercase;
  color: #000;
}
.do-desc {
  font-family: 'Argon-Regular', sans-serif;
  font-size:15px;
  text-align: right;
  color: #5C5C57;
  width: 80%;
  padding-bottom: 12px;
  opacity: 0;
}
.do-line {
  padding-bottom: 19px;
  width: 100%;
}
.do-arrow {
  position: relative;
  width: 20px;
  cursor: pointer;
}
.do-arrow-float {
  position: absolute;
  top: 0;
}
.do-row {
  height: 155px;
  position: relative;
}
.do-line-container {
  position: absolute; 
  bottom: 0
}
.do-arrow-container {
  position: absolute; 
  top: 15px
}
.do-col {
  /*position: relative;*/
  width: 80px !important;
}
.do-img {
  width: 93%;
}
.build-text-float {
  position: absolute; 
  bottom: 10px; 
  width: 45%;
}

.step-names {
  position: absolute;
  left: 40px;
  top: 46px;
}
.step-slider {
  position: absolute;
  left: 0px;
  top: 50px;
}
.proyect-custom-height {
  height: 100px;
}
.proyect-header {
  font-family: 'Fractul-Medium', sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
  color: #595A5C
}
.proyect-title {
  font-family: 'Fractul-Medium', sans-serif;
  font-size: 35px;
  text-align: left;
  color: #000
}
.proyect-desc {
  font-family: 'Argon-Regular', sans-serif;
  font-size: 16px;
  text-align: left;
  padding-right: 25px;
  color: #5C5C57
}
.proyect-img {
  width: 100%;
}
.proyect-container {
  max-width: 1320px !important;
}
.proyect-sub-container {
  position: relative;
  left: 10%;
  padding-right: 10% !important;
}
.gallery-img {
  width: 100%;
}
.gallery-container-1 {
  height: 370px;
}
.gallery-container-2 {
  height: 624px;
}
.map-desc {
  font-family: 'Argon-Oblique', sans-serif;
  font-size: 15px;
  text-align: right;
  color: #5C5C57;
}
.map-image {
  width: 100%;
}
.contact-container {
  margin-top: 3px;
  margin-bottom: 3px;
  cursor: pointer;
}
.contact-title {
  font-family: 'Argon-Regular', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #000
}
.contact-social-icons {
  cursor: pointer;
  height: 20px;
}
.contact-icon {
  width: 15px;
}
.contact-info {
  font-family: 'Argon-Regular', sans-serif;
  font-size: 14px;
  text-align: left;
  color: #000
}
.footer {
  padding: 30px !important;
  background-color: #898989;
}
.footer_logo {
  height: 70px;
}
.footer-text {
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
.footer-text:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.text-linear-down {
  animation: growDown 0.5s linear;
  font-size: 30px;
  padding-bottom: 4px;
}
.text-linear-up {
  animation: growUp 0.5s linear;
  font-size: 50px;
}

@keyframes growDown {
  0% {
    font-size: 50px;
  }
  50% {
    font-size: 40px;
  }
  100% {
    font-size: 30px;
  }
}
@keyframes growUp {
  0% {
    font-size: 30px;
  }
  50% {
    font-size: 40px;
  }
  100% {
    font-size: 50px;
  }
}


@media (max-width: 460px) {
  .nav-link {
    font-family: 'Argon-Regular', sans-serif !important;
    font-size: 10px !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .modal-dialog, .modal-content {
    background-color: transparent !important;
    border: 0 !important;
  }
  .navbar-collapse {
    background-color: #fbfbfa !important;
  }
  #service_0, #service_1, #service_3 {
    position: relative;
  }

  .home-section {
    padding: 60px 0 30px 0!important;
  }
  .header-title {
    font-size: 10px;  
  }
  .header-img {
    width: 30%;
  }
  .section {
    padding: 20px 0 !important;
  }
  .section-title {
    font-size: 18px !important;
  }
  .section-desc {
    font-size: 15px !important;
    padding: 10px 30px;
    text-align: center;
  }
  .logo {
    width: 50%;
  }
  .slide-form {
    width: 100%;
  }
  .trazo-carousel {
    width: 95%;
  }
  .carousel-item-custom {
    height: auto;
  }
  .do-title {
    font-family: 'Argon-Oblique', sans-serif;
    font-size: 20px;
    text-align: right;
    text-transform: uppercase;
    color: #000;
}
  .text-linear-up {
    animation: growUp 0.5s linear;
    font-size: 20px;
  }
  .text-linear-down {
    animation: growDown 0.5s linear;
    font-size: 13px;
    padding-bottom: 4px;
  }
  .do-line {
    padding-bottom: 0px;
  }
  .do-row {
    height: 70px;
  }
  .build-text-float {
    position: relative;
    top: 0;
    width: 100%;
  }
  .do-desc {
    font-size: 13px;
    text-align: center;
    width: 100%;
    padding-bottom: 0;
  }
  .do-img {
    width: 100%;
  }
  .do-arrow-float {
    position: relative;
    top: 0;
    padding-bottom: 10px;
  }
  .proyect-custom-height {
    height: 35px;
  }
  .proyect-sub-container {
    position: relative;
    left: 0;
    padding-right: 15px !important;
  }
  .footer_logo {
    height: 100px;
  }

  @keyframes growDown {
    0% {
      font-size: 20px;
    }
    50% {
      font-size: 15px;
    }
    100% {
      font-size: 13px;
    }
  }
  @keyframes growUp {
    0% {
      font-size: 13px;
    }
    50% {
      font-size: 15px;
    }
    100% {
      font-size: 20px;
    }
  }
}